import { Select, SelectProps } from "antd";
import { useState } from "react";

interface MultipleIdenticalValueSelectProps extends SelectProps {
  filterOption: (input: string, option?: any) => boolean
}

/**
 * Workaround for https://github.com/ant-design/ant-design/issues/9297
 * Uses an internal list to keep track of selected labels and values
 */
export default function MultipleIdenticalValueSelect(props: MultipleIdenticalValueSelectProps) {
  const { options, value } = props;
  const [prevValue, setPrevValue] = useState<any>();

  console.log("props", props);

  function handleOnChange(value: string, option: any) {
    if (!options) {
      console.warn("No options provided to MultipleIdenticalValueSelect");
      return;
    }

    if (props.onChange) {
      setPrevValue(value);

      // Get real value
      const realValue = options?.find((o: any) => o.label === value)!!.value;
      props.onChange(realValue, option);
      console.log("handling onchange", value, option, realValue);
    }
  }

  const parsedOptions = options?.map((option: any) => ({
    label: option.label,
    value: option.label
  })) ?? [];

  return (
    <Select
      {...props}
      options={parsedOptions}
      onChange={handleOnChange}
      value={prevValue ?? value}
    />
  )
}